import React, { useEffect } from "react";
import styled from "styled-components";
import { mobileSize } from "../util/variables";
import theme from "../util/theme";
import { ThankYou } from "../components/PageSegments/subscription/ThankYouV2";
import { BasicLayout } from "../layouts/BasicLayout";
import { subscription_success, trackEvent } from "../services/mixpanel/mixpanel";
import {
  web_acq_post_payment_fail,
  web_acq_post_payment_view,
} from "../services/mixpanel/acquisition-events";
import { triggerCustomEvent } from "../services/google-tag-manager";
import { GTM_CUSTOM_EVENT } from "../util/types";
import { sendFBConversionEvent } from "../services/pixel";
import { useAuth } from "../hooks/useAuth";

export default (data: any) => {
  const { search } = data.location;
  const urlParams = new URLSearchParams(search);
  const sessionParam = urlParams.get("session_id");
  const uidParam = urlParams.get("uid");
  const trialPrice = urlParams.get("trial_price");
  const { user, loading } = useAuth();

  useEffect(() => {
    if (trialPrice) {
      trackEvent("PaidTrial_PurchaseCompleted", { price: trialPrice });
    }
  }, [trialPrice]);

  useEffect(() => {
    if (loading) return;
    if (sessionParam) {
      web_acq_post_payment_view();
      if (uidParam || user?.uid) {
        sendFBConversionEvent({
          value: 69.99,
          uid: uidParam ?? user!.uid,
          currency: "USD",
        });
      }

      triggerCustomEvent(GTM_CUSTOM_EVENT.PURCHASE);
    } else {
      web_acq_post_payment_fail();
    }

    localStorage.clear();
    sessionStorage.setItem("landing_page", "");
    subscription_success({ subscription_type: "yearly" });
  }, [sessionParam, uidParam, loading, user]);

  return (
    <BasicLayout noIndex>
      <FlexContainer>
        <ThankYou />
      </FlexContainer>
    </BasicLayout>
  );
};

const FlexContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background: ${theme.WHITE_COLOR};

  @media ${mobileSize} {
    justify-content: flex-start;
  }
`;
